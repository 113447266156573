<template>
  <div class="about container">
    <h2>คุ้มกัน?</h2>
    <h4>โครงการเล็ก ๆ ที่มีความฝันยิ่งใหญ่</h4>
    <p>
      พวกเรามารวมตัวกัน เพราะอยากเห็นคนไทยปลอดภัยและปลอดโรค โดยการตัดสินใจด้านสุขภาพอย่างมีหลักการและเหตุได้ด้วยตนเอง
    </p>

    <p>ในสถานการณ์ปัจจุบัน ที่เราต้องเจอทั้งโรคภัยใหม่ ๆ อย่างโควิด-19 และความเร่งด่วนในการฉีดวัคซีน เพื่อให้เกิดภูมิคุ้มกันหมู่ขึ้นในกลุ่มประชาชน ความท้าทายที่เกิดขึ้น คือ ปรากฎการณ์ความกังวลต่อการรับวัคซีน (vaccine hasitancy) เพราะข้อมูลเชิงวิจัยเกี่ยวกับวัคซีนยังมีน้อยมาก และผลข้างเคียงจากวัคซีนก็มีออกมาให้เห็นบ่อย ๆ </p>
    
    <h4>แล้วเราจะตัดสินใจอย่างไรดี?</h4>
    <p>ในเมื่อใจหนึ่งก็กลัวโรค แต่อีกใจหนึ่งก็กลัวผลข้างเคียง การตัดสินใจครั้งนี้ไม่ใช่เรื่องง่ายเลย</p>

    <p>จากปรากฎการณ์ครั้งนี้จึงเกิดการรวมตัวกันของแพทย์ วิศวกรรมการแพทย์ นักออกแบบ และนักวิจัย ในการสร้างเครื่องมือที่จะช่วยเป็นเพื่อนคู่คิด ช่วยตัดสินใจอย่างมีสติ มีหลักการตามปัจจัยที่แตกต่างของแต่ละคน</p>
  </div>
</template>
